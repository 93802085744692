import React from "react"

import Layout from '../../../components/layout'
import SEO from '../../../components/seo'

import {
  formatMarkdown,
  formatOffice,
  formatContact,
  formatImageField,
  formatBrand,
  longformFormater,
} from "../../../utils/previewFormaters"

import {
  contentfulClient,
  getContentfulEntryID,
} from "../../../utils/contentfulClient"

import QaSingle from "../../../content/qa/QaSingle"

class QAPreview extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      data: null,
    }
    this.entryID = getContentfulEntryID()
  }

  componentDidMount() {
    if (this.entryID) {
      contentfulClient()
        .getEntries({
          "sys.id": this.entryID,
          content_type: `qAndA`,
          include: 10,
          limit: 1,
        })
        .then(entries => this.formatData(entries))
        .catch(err => console.log(err))
    }
  }

  formatData(entries) {
    let entry = entries.items[0].fields
    let data = { qa: null }

    if (entry) {
      data.qa = {
        title: entry.title,
        contact: entry.contact ? formatContact(entry.contact) : null,
        office: entry.office ? formatOffice(entry.office) : null,
        createdAt: entry.createdAt,
        headerColor: entry.headerColor,
        headerImage: entry.headerImage
          ? formatImageField(entry.headerImage)
          : null,
        content: entry.content ? formatMarkdown(entry.content) : null,
      }

      this.setState({
        data: data,
      })
    }
  }

  render() {
    return (
      <Layout>
        <SEO title={`QA Preview`} description={`QA Preview`} />
        <div style={{ minHeight: `100vh` }}>
          {this.state.data && <QaSingle data={this.state.data} />}
        </div>
      </Layout>
    )
  }
}

export default QAPreview
